<template>
  <div class="products category-med grey lighten-3 px-2 px-md-6">
    <Breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container class="py-0">
      <CategoryTitle
        v-if="
          !$ebsn.meta(category, 'category_info.OVERLAYED_HEADER_IMAGE', true)
        "
        :category="category"
        :count="count"
        :selectedCategory="selectedCategory"
      />
      <template v-else>
        <ebsn-meta
          :target="category"
          path="category_info.TITLE"
          :default-value="category.name"
          tag="h1"
          :style="{ color: $ebsn.meta(category, 'category_info.COLOR') }"
        ></ebsn-meta>
        <v-card outlined class="old-category-card ">
          <v-img
            :src="$ebsn.meta(category, 'category_info.HEADER_IMAGE')"
            contain
            onerror="this.onerror=null;this.src='/no-icon.png'"
            width="100%"
          >
            <div class="category-description elevation-2">
              <ebsn-meta
                class="SUBTITLE"
                :target="category"
                path="category_info.SUBTITLE"
                tag="h3"
                @click="clicked"
              ></ebsn-meta>
              <ebsn-meta
                class="description"
                :target="category"
                path="category_info.DESCRIPTION"
                tag="p"
                @click="clicked"
              ></ebsn-meta>
            </div>
          </v-img>
        </v-card>
      </template>
      <v-chip-group show-arrows v-if="category.children">
        <v-chip
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
          label
          link
          class="mr-2 mb-2 accent"
          exact-active-class="secondary"
          :to="
            category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }
          "
        >
          {{ subCategory.name }}
        </v-chip>
      </v-chip-group>
    </v-container>
    <v-container>
      <ProductListGrid
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="categoryId || category.categoryId"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.old-category-card {
  position: relative;
  .category-description {
    border-radius: 8px;
    position: absolute;
    right: 20px;
    background: rgba(255, 255, 255, 0.9);
    top: 40px;
    width: 45%;
    padding: 30px;
  }
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";
import get from "lodash/get";
import clickHandler from "~/mixins/clickHandler";
import analyticsServiceGTM from "@/commons/service/analytics/analyticsServiceGTM";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins, clickHandler],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
  },
  data() {
    return { count: -1, categoryId: null };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  },
  metaInfo() {
    const categories = Object.values(
      analyticsServiceGTM.getCategoryPath(this.category.categoryId)
    );
    const leafCategory = categories[categories.length - 1];
    const parentCategory = categories[categories.length - 2];
    console.log("ppp", parentCategory, leafCategory);
    let title = null;
    let description = null;
    // This if checks for a real category-med
    if (categories.length && leafCategory && parentCategory) {
      title = get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.$t("meta.category.secondLevel.title", [leafCategory])
      );
      description = get(
        this.category,
        "metaData.category_seo.SEO_DESCRIPTION",
        this.$t("meta.category.secondLevel.description", [
          leafCategory,
          parentCategory
        ])
      );
      // Instead, this case refers to a Custom Category which couldn't have parent category, so these are the SEO rules as category-main
    } else {
      title = get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.$t("meta.category.firstLevel.title", [this.category.name])
      );
      description = get(
        this.category,
        "metaData.category_seo.SEO_DESCRIPTION",
        this.$t("meta.category.firstLevel.description", [this.category.name])
      );
    }
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Alisupermercati.it"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
